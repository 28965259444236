/* You can add global styles to this file, and also import other style files */

.main-color{
    color: #FD8E01;
}
.backgroundcolor{
  background-color:#FD8E01;
}
.breadcrumbs-section:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80%;
  content: '';
  z-index: -1;
  background:
linear-gradient(27deg, #151515 5px, transparent 5px) 0 5px,
linear-gradient(207deg, #151515 5px, transparent 5px) 10px 0px,
linear-gradient(27deg, #222 5px, transparent 5px) 0px 10px,
linear-gradient(207deg, #222 5px, transparent 5px) 10px 5px,
linear-gradient(90deg, #1b1b1b 10px, transparent 10px),
linear-gradient(#1d1d1d 25%, #1a1a1a 25%, #1a1a1a 50%, transparent 50%, transparent 75%, #242424 75%, #242424);
background-color: #131313 !important;
background-size: 20px 20px !important;
  /* background: rgba(0, 0, 0, 0.75); */
}
/* for spinner */
.loadWrapper {  
    background: rgba(0, 0, 0, 0.3);  
    width: 100% ;  
    height: 100% ;  
    position: fixed;  
    top: 0 px;  
    left: 0 px;  
    z-index: 99999;  
  }.loader {  
    border: 5 px solid #f3f3f3; /* Light grey */  
    border-top: 5px solid #3d3e3f; /* gray */  
   position: absolute;  
   left: 50%;  
   top: 50%;  
   border-radius: 50%;  
   width: 50px;  
   height: 50px;  
   animation: spin 2s linear infinite;  
  }  
  @keyframes spin {  
   0% { transform: rotate(0deg); }  
   100% { transform: rotate(360deg); }  
  }


  ul, ol {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
}

ul.breadcrumb-menu li {
    display: inline-block;
    text-transform: uppercase;
}

ul.breadcrumb-menu li a{
    color: #fff;
}
ul.breadcrumb-menu li span {
    color: #FD8E01;
}
ul.breadcrumb-menu li a:after {
    content: '>';
    font-family: 'Font Awesome 5 Pro';
    margin-left: 13px;
    margin-right: 10px;
    color: #fff;
}


.ngx-slider .ngx-slider-pointer
{
  background-color:#FD8E01 !important;
}
.ngx-slider .ngx-slider-pointer.ngx-slider-active::after
{
  background-color:#fff !important;
}

.ngx-slider .ngx-slider-selection
{
  background-color:#FD8E01 !important;
}
.ngx-slider .ngx-slider-bar 
{
  background-color:#FD8E01 !important;

}

/* for spinner */
.spinner {
  margin: 10px auto;
  width: 60px;
  height: 50px;
  text-align: center;
  position: absolute;  
  left: 50%;  
  top: 50%;  
}

.spinner > div {
  background-color: #FD8E01;
  height: 100%;
  width: 6px;
  display: inline-block;
  margin-right:3px;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% {
       transform: scaleY(1.0);
       -webkit-transform: scaleY(1.0);
     }
}
.my-pagination /deep/ .ngx-pagination .current {
  background: #FD8E01;
}
/* .klb-shop-breadcrumb {
  /* background-image: url(https://klbtheme.com/chakta/wp-content/uploads/2021/01/breadcrumbs-bg.jpg); 
  background-image: url("/assets/img/theme/background11.jpeg");
 background-repeat: no-repeat !important;
 background-size: cover !important;

} */